<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form">
                    <v-card :elevation="1">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0 mt-1">
                                    <v-row no-gutters>
                                        <v-col cols="4" offset="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="4">Purchase Invoice </v-col>
                                                <v-col cols="8">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="purchase"
                                                        @focus="$store.dispatch('purchase/getPurchaseInvoice')"
                                                        :items="$store.getters['purchase/invoices']"
                                                        :loading="$store.getters['purchase/loading']"
                                                        item-text="invoice_id"
                                                        item-value="id"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <purchase-invoice :purchaseId="invoiceId" v-if="show"></purchase-invoice>
        </v-row>
    </v-container>
</template>

<script>
import purchaseInvoice from '../../components/PurchaseInvoice';

export default {
    name: 'PurchaseReport',

    components: {
		"purchase-invoice": purchaseInvoice
    },

    data: ()=> ({
        purchase: null,
        invoiceId: null,
        show: false,
    }),

    watch: {
        purchase(purchase) {
            if (purchase == null) return
            this.invoiceId = purchase.id;
            this.show = true;
        },
    },
}
</script>

<style lang="scss" scoped>
</style>